export default {
  init() {
    console.log("common");
  },
  finalize() {
    (function($) {
      "use strict";

      $(document).ready(function() {
        eventListener();
      });

      function eventListener() {
        $(".plus-btn").on("click", showText);
      }

      function showText() {
        console.log("plus clicked");
        $(this)
          .next(".hidden-item")
          .slideToggle("slow");
      }
    })(jQuery);
  }
};

<template>
  <div id="menu-bar" class="container-fluid">
    <div class="row">
      <ul class="mx-auto d-flex align-items-center category-filter">
        <li
          class="px-5"
          v-for="filter in filters"
          @click="updateQuery(filter.key)"
          :class="{ active: filter.isActive }"
        >
          {{ filter.value }}
        </li>
      </ul>
    </div>

    <div class="modal-overlay" v-if="modal">
      <div class="modal-component is-scrollable">
        <section class="modal-component-header d-flex">
          <h4 class="modal-title">
            Ask <strong style="color: #A6CC46">Talance</strong> Group Series
          </h4>
          <button
            type="button"
            class="ml-auto close-modal-btn"
            @click="closeModal"
          >
            <i class="fal fa-times-circle fa-lg"></i>
          </button>
        </section>
        <section class="modal-component-body">
          <div class="inner-body">
            <div class="row">
              <div class="col-12">
                <atg-questions></atg-questions>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "../Bus.js";
import atgQuestions from "../components/atg-questions.vue";

export default {
  name: "MenuBar",
  component: {
    atgQuestions
  },
  data() {
    return {
      filters: [
        {
          key: "candidates",
          value: "Candidates",
          isActive: false
        },
        {
          key: "hiring-managers",
          value: "Hiring Managers",
          isActive: false
        },
        {
          key: "questions",
          value: "All Questions",
          isActive: false
        },
        {
          key: "videos",
          value: "Videos",
          isActive: false
        }
      ],
      modal: false
    };
  },
  mounted() {
    Bus.$on("selectMenu", data => {
      console.log(data);
      let filters = this.filters;
      for (let i = 0; i < filters.length; i++) {
        if (filters[i].key === data) {
          filters[i].isActive = true;
        } else {
          filters[i].isActive = false;
        }
      }
    });
  },
  methods: {
    updateQuery(category) {
      if (category === "questions") {
        this.modal = true;
      } else {
        Bus.$emit("updateCategory", category);
        const element = document.getElementById("blog-card");
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start"
        });
      }

      let filters = this.filters;
      for (let i = 0; i < filters.length; i++) {
        if (filters[i].key === category) {
          filters[i].isActive = true;
        } else {
          filters[i].isActive = false;
        }
      }
    },
    closeModal() {
      this.modal = false;
      this.filters[2].isActive = false;
    }
  }
};
</script>

<style lang="scss">
@import "../styles/_global-vars.scss";

#menu-bar {
  background: $accent;

  .category-filter {
    list-style: none;
    display: inline-flex;
    margin-bottom: 0px;
    padding-left: 0px;
    flex-wrap: wrap;

    li {
      font-size: 1.25rem;
      font-weight: 500;
      transition: all 500ms;
      cursor: pointer;
      padding: 0.5rem 0;

      &:hover {
        color: $primary;
      }
    }
  }

  #atg-season {
    color: $white;
    font-size: 1.25rem;
    font-weight: 500;
  }

  .dropdown-menu.show {
    background: #052031;

    a {
      transition: all 500ms;
    }

    button:hover a {
      color: $accent;
    }
  }

  .active {
    background-color: white;
    color: $primary !important;
    font-weight: bold !important;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(#000, 0.6);
    margin: auto;
    z-index: 2090;
    cursor: s-resize;

    .modal-component.is-scrollable {
      height: calc(100% - 200px);
      max-height: 700px;

      .modal-component-body {
        height: calc(100% - 79px);
        overflow-y: auto;
      }
    }

    .modal-component {
      display: inline-block;
      position: absolute;
      z-index: 2091;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      animation-name: sm-modal;
      animation-duration: 400ms;
      transition-timing-function: ease-in;
      height: initial;
      max-width: 768px;
      width: calc(100% - 20px);
      background-color: #e9eef0;
      border-top-right-radius: 30px;
      border-bottom-left-radius: 30px;
      cursor: auto;

      &-header {
        color: $white;
        background-color: $primary;
        padding: 0.75rem 1.25rem;
        border-top-right-radius: 30px;

        .close-modal-btn {
          border: none;
          color: $white;
          background-color: transparent;
        }
      }

      &-body {
        background-color: #e9eef0;
        padding: 0.75rem 1.25rem;
      }

      &-footer {
        padding: 0.75rem 1.25rem;
        background-color: $white;
        border-bottom-left-radius: 30px;
      }
    }

    @keyframes sm-modal {
      from {
        transform: translate(-50%, -150%);
        opacity: 0;
      }
      to {
        transform: translate(-50%, -50%);
        opacity: 1;
      }
    }
  }

  @media (max-width: 576px) {
    .category-filter {
      display: inline-block !important;

      li {
        padding: 0.5rem !important;
        margin: auto;
        text-align: center;
      }
    }
  }
}
</style>

<template>
  <div id="latest-post">
    <div v-show="this.featuredState === 'show'">
      <div class="row my-4 d-flex align-items-center" v-for="post in posts">
        <div class="col-md-5 text-center pr-3" v-for="post in posts">
          <small id="latest-series" class="pb-3">Latest Post</small>
          <h3 v-html="post.title"></h3>
          <div id="title-separator"></div>
          <div class="space-2"></div>
          <p v-html="post.excerpt"></p>
          <a :href="post.link">
            <button class="btn cpl-arrow-btn">Read More</button>
          </a>
        </div>
        <div class="col-md-7 mx-auto py-3">
          <div v-if="post.video" class="video-container">
            <div class="embed-container">
              <iframe
                :src="post.video"
                frameborder="0"
                webkitAllowFullScreen
                mozallowfullscreen
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div class="inner-container" v-else>
            <a :href="post.link">
              <img :src="post.thumbnail" :alt="post.title" width="100%" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "../Bus.js";

export default {
  data() {
    return {
      posts: null,
      featuredState: "show"
    };
  },
  mounted() {
    axios.get("/wp-json/atg/blog/latest").then(response => {
      this.posts = response.data;
    });

    Bus.$on("hideFeaturedPost", hide => {
      this.featuredState = "hide";
    });
  },
  created() {},
  methods: {}
};
</script>

<style lang="scss">
@import "../styles/_global-vars.scss";

#latest-post {
  #latest-series {
    color: #19305b;
  }

  #title-separator {
    background: $accent;
    height: 3px;
    width: 70px;
    position: relative;
    transform: translate(185px, 15px);
  }

  .video-container {
    height: 400px;
    width: 100%;
    margin-top: -70px;

    .embed-container {
      position: relative;
      padding-bottom: 82%;
      height: 0;
      overflow: hidden;
      max-width: 100%;

      iframe {
        min-height: 400px;
        width: 100%;
      }

      iframe,
      object,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .cpl-arrow-btn {
    background: #092231;
    border-top: 3px solid $accent;
    border-radius: 0;
    padding: 10px 35px;
    color: white;

    a {
      color: white;
    }
  }
}
</style>

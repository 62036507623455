import Vue from "vue/dist/vue.js";
import featuredPost from "./components/featured-post.vue";
import blogCard from "./components/blog-card.vue";
import menuBar from "./components/menu-bar.vue";
import atgQuestions from "./components/atg-questions.vue";
import Bus from "./Bus.js";
import Swiper from "swiper";

window.Event = new Vue();
window.Vue = Vue;

import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);

import ATGQuestions from "./components/atg-questions.vue";
Vue.component("atgQuestions", ATGQuestions);

import SearchBar from "./components/atg-search.vue";
Vue.component("atgSearch", SearchBar);

export function askTalance() {
  new Vue({
    el: "#app",
    name: "atg-app",
    components: {
      featuredPost,
      blogCard,
      menuBar,
      atgQuestions
    }
  });
}

<template>
  <div id="atg-questions">
    <ul class="mb-0">
      <li v-for="question in questions">
        <strong>
          <a :href="question.link" v-html="question.title">
            {{ question.title }}
          </a>
        </strong>
      </li>
    </ul>
    <div class="container mt-3">
      <div class="row">
        <div class="col-12">
          <paginate
            v-model="pageNum"
            :page-count="maxPage"
            :page-range="3"
            :margin-pages="2"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :click-handler="pageClick"
            :container-class="'pagination'"
            :page-class="'page-item'"
          >
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";

export default {
  name: "atgQuestions",
  components: {
    Paginate
  },
  data() {
    return {
      questions: null,
      maxPage: 0,
      pageNum: 1
    };
  },
  mounted() {
    axios.get("/wp-json/atg/questions/seasons").then(response => {
      this.questions = response.data[0].questions;
      this.maxPage = response.data[0].maxPages;
    });
  },
  methods: {
    pageClick(num) {
      let params = {
        pageNum: num
      };
      axios.get("/wp-json/atg/questions/seasons", { params }).then(response => {
        this.questions = response.data[0].questions;
        this.maxPage = response.data[0].maxPages;
      });
    }
  }
};
</script>

<style lang="scss">
@import "../styles/_global-vars.scss";

#atg-questions {
  .pagination li.disabled {
    background-color: transparent;
    padding: 0;
    margin: 0;
  }
  .page-item.active {
    background-color: transparent;
  }
}
</style>

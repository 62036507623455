export default {
  init() {
    console.log("Team");
  },
  finalize() {
    (function($) {
      "use strict";

      $(function() {
        $(".single-member").on("click", function() {
          var id = $(this).data("id");

          $(".team-overlay").each(function() {
            if ($(this).data("id") == id) {
              $(this).css("display", "flex");

              //Animate this
              const s1 = new TimelineMax();
              s1.to(
                [$(this)],
                0.8,
                {
                  autoAlpha: 1,
                  height: "100%",
                  ease: Back.easeOut.config(1.4)
                },
                0
              );
            }
          });
        });

        $(".team-overlay").on("click", function() {
          // $(this).hide();

          function goHide() {
            $(this).hide();
          }

          //Animate this
          const s1 = new TimelineMax({
            onComplete: goHide
          });
          s1.to(
            [$(this)],
            0.8,
            {
              autoAlpha: 0,
              height: 0,
              ease: Back.easeOut.config(1.4)
            },
            0
          );
        });

        $(".owl-carousel").owlCarousel({
          items: 1,
          loop: true,
          video: true,
          lazyLoad: true,
          center: true
        });
      });
    })(jQuery);
  }
};

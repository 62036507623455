import Cookies from "js-cookie";
export default {
  init() {
    console.log("common");
  },
  finalize() {
    function animate_all() {
      const s1 = new TimelineMax();
      s1.set(
        [
          ".anime-0",
          ".anime-1",
          ".anime-2",
          ".anime-3",
          ".anime-4",
          ".anime-5",
          ".anime-6",
          ".anime-7",
          ".anime-8",
          ".anime-9"
        ],
        {
          autoAlpha: 0
        }
      )
        .to(
          [".anime-0"],
          1,
          {
            autoAlpha: 1
          },
          0.75
        )
        .to(
          [".anime-1"],
          1,
          {
            autoAlpha: 1
          },
          1
        )
        .to(
          [".anime-2"],
          1,
          {
            autoAlpha: 1
          },
          2
        )
        .to(
          [".anime-3"],
          1,
          {
            autoAlpha: 1
          },
          3
        )
        .to(
          [".anime-4"],
          1,
          {
            autoAlpha: 1
          },
          4
        )
        .to(
          [".anime-5"],
          1,
          {
            autoAlpha: 1
          },
          5
        )
        .to(
          [".anime-6"],
          1,
          {
            autoAlpha: 1
          },
          6
        )
        .to(
          [".anime-7"],
          1,
          {
            autoAlpha: 1
          },
          7
        )
        .to(
          [".anime-8"],
          1,
          {
            autoAlpha: 1
          },
          8
        )
        .to(
          [".anime-9"],
          1,
          {
            autoAlpha: 1
          },
          9
        );

      const s2 = new TimelineMax();
      s2.set(".single-member", {
        autoAlpha: 0
      }).staggerTo(
        ".single-member",
        0.5,
        {
          autoAlpha: 1
        },
        0.25
      );
    }

    animate_all();

    const talentModal = document.querySelector("#talentTutorialVideo");
    const tutorialTrigger = document.querySelector("#tutorialTrigger");
    if (talentModal && tutorialTrigger) {
      const watched = Cookies.get("talance-tutorial-video-watched");
      console.log(watched);
      if (!watched) {
        Cookies.set("talance-tutorial-video-watched", "true");
        tutorialTrigger.click();
      }
    }

    //animate arrow circle button

    (function($) {
      "use strict";

      $(function() {
        $(".cpl-arrow-btn").hover(
          function() {
            const s1 = new TimelineMax();
            s1.to(
              $("span", this),
              0.25,
              {
                x: 5
              },
              0
            );
          },
          function() {
            const s1 = new TimelineMax();
            s1.to(
              $("span", this),
              0.1,
              {
                x: 0
              },
              0
            );
          }
        );

        var hammyState = false;

        $("#hammy-container, .close-menu").on("click", function() {
          if (hammyState === false) {
            hammyOpen();
            menuOpen();
            hammyState = true;
          } else {
            hammyClose();
            menuClose();
            hammyState = false;
          }
        });

        function hammyOpen() {
          const s1 = new TimelineMax();

          s1.set(["#ham-1", "#ham-2", "#ham-3"], {
            rotation: 0
          })
            .to(
              ["#ham-1"],
              0.8,
              {
                rotation: "-=45",
                transformOrigin: "right top",
                // stroke: "rgba(255, 255, 255, 0.80)",
                ease: Back.easeOut.config(1.4)
              },
              0
            )
            .to(
              ["#ham-2"],
              0.8,
              {
                autoAlpha: 0,
                // stroke: "rgba(255, 255, 255, 0.80)",
                ease: Back.easeOut.config(1.4)
              },
              0
            )
            .to(
              ["#ham-3"],
              0.8,
              {
                rotation: "+=45",
                // stroke: "rgba(255, 255, 255, 0.80)",
                transformOrigin: "right bottom",
                ease: Back.easeOut.config(1.4)
              },
              0
            );
        }

        function hammyClose() {
          const s1 = new TimelineMax();

          s1.to(
            ["#ham-1"],
            0.8,
            {
              rotation: "+=45",
              // stroke: "rgba(0, 0, 0, 0.80)",
              transformOrigin: "right top",
              ease: Back.easeOut.config(1.4)
            },
            0
          )
            .to(
              ["#ham-2"],
              0.8,
              {
                autoAlpha: 1,
                // stroke: "rgba(0, 0, 0, 0.80)",
                ease: Back.easeOut.config(1.4)
              },
              0
            )
            .to(
              ["#ham-3"],
              0.8,
              {
                rotation: "-=45",
                // stroke: "rgba(0, 0, 0, 0.80)",
                transformOrigin: "right bottom",
                ease: Back.easeOut.config(1.4)
              },
              0
            );
        }

        var NavWrapper = $("#overlay-nav-container");

        function menuOpen() {
          NavWrapper.show();

          //Animate this
          const s1 = new TimelineMax();
          s1.to(
            [NavWrapper],
            0.8,
            {
              autoAlpha: 1,
              height: "100%",
              ease: Back.easeOut.config(1.4)
            },
            0
          );
        }

        function menuClose() {
          function goHide() {
            NavWrapper.hide();
          }

          //Animate this
          const s1 = new TimelineMax({
            onComplete: goHide
          });
          s1.to(
            [NavWrapper],
            0.8,
            {
              autoAlpha: 0,
              height: 0,
              ease: Back.easeOut.config(1.4)
            },
            0
          );
        }

        $(".owl-carousel.team-slider").owlCarousel({
          loop: true,
          nav: false,
          dots: true,
          items: 1
        });

        $(".owl-carousel.testimonial-carousel").owlCarousel({
          loop: true,
          nav: false,
          dots: true,
          navText: [
            '<i class="fal fa-2x fa-long-arrow-left"></i>',
            '<i class="fal fa-2x fa-long-arrow-right"></i>'
          ],
          items: 1
        });

        $(".owl-carousel.photo-slider").owlCarousel({
          loop: true,
          nav: true,
          autoplay: true,
          autoplayTimeout: 8000,
          autoplayHoverPause: true,
          autoplaySpeed: 8000,
          dots: true,
          navText: [
            '<i class="fal fa-2x fa-long-arrow-left"></i>',
            '<i class="fal fa-2x fa-long-arrow-right"></i>'
          ],
          items: 1
        });

        $(".owl-carousel.video-slider").owlCarousel({
          loop: true,
          nav: true,
          autoplay: false,
          autoplayTimeout: 8000,
          autoplayHoverPause: true,
          autoplaySpeed: 8000,
          dots: false,
          navText: [
            '<i class="far fa-2x fa-angle-left"></i>',
            '<i class="far fa-2x fa-angle-right"></i>'
          ],
          navClass: ["video-prev", "video-next"],
          responsive: {
            // breakpoint from 0 up
            0: {
              items: 1
            },
            // breakpoint from 768 up
            768: {
              items: 2
            }
          }
        });
      });
    })(jQuery);
  }
};

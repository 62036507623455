export default {
  init() {
    console.log("Team");
  },
  finalize() {
    (function($) {
      "use strict";

      $(function() {
        $(".owl-carousel").owlCarousel({
          items: 1,
          loop: true,
          video: true,
          lazyLoad: true,
          center: true
        });
      });
    })(jQuery);
  }
};

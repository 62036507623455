import jQuery from "jquery";

export default {
  init() {
    console.log("About Us");
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    (function($) {
      "use strict";

      $(function() {
        //Trigger modal when clicking the image
        $(".videoModal-img").on("click", function() {
          $("#videoModal").modal("show");
        });

        //script to stop playing video when modal is closed
        var $videoSrc;
        $(".videoModal-img").click(function() {
          $videoSrc = $(this).attr("id");
        });

        $("#videoModal").on("shown.bs.modal", function(e) {
          $("#video").attr("src", $videoSrc + "?autoplay=1");
        });

        $("#videoModal").on("hide.bs.modal", function(e) {
          // a poor man's stop video
          $("#video").attr("src", $videoSrc);
        });
      });
    })(jQuery);
  }
};

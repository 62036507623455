<template>
  <div id="atg-search">
    <input
      type="search"
      v-model="keywords"
      placeholder="What do you want to ask the Talance Group?"
      v-on:keyup="updateQuery"
    />
    <button class="btn btn-accent" @click="updateQuery">Search</button>
  </div>
</template>

<script>
import Bus from "../Bus.js";

export default {
  name: "atgSearch",
  data() {
    return {
      keywords: ""
    };
  },
  mounted() {},
  methods: {
    updateQuery() {
      Bus.$emit("changeKeyword", this.keywords);
      Bus.$emit("hideFeaturedPost", "hide");
    }
  }
};
</script>

<style lang="scss">
@import "../styles/_global-vars.scss";

#atg-search {
  input {
    width: 100%;
    height: 40px;
    border: 1px solid #767676;
  }

  button {
    position: absolute;
    right: 15px;
    overflow: hidden;
    background-color: $accent;
    border: 0;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0.61rem 2rem;
    border-radius: 0;
  }
}
</style>

import jQuery from "jquery";
import tippy from "tippy.js";

export default {
  init() {
    console.log("home");
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    (function($) {
      "use strict";

      $(function() {
        var videoID = $("#videoModal").attr("id");
        var thumbnailID = $(".videoModal-img").attr("id");

        //Trigger modal when clicking the image
        $(".videoModal-img").on("click", function() {
          $("#videoModal").modal("show");
          // if (videoID == thumbnailID) {
          //   $("#videoModal").modal("show");
          // }
        });

        //script to stop playing video when modal is closed
        var $videoSrc;
        $(".videoModal-img").on("click", function() {
          $videoSrc = $(this).attr("id");
        });

        $("#videoModal").on("shown.bs.modal", function(e) {
          $("#video").attr("src", $videoSrc + "?autoplay=1");
        });

        $("#videoModal").on("hide.bs.modal", function(e) {
          // a poor man's stop video
          $("#video").attr("src", $videoSrc);
        });

        //Tippy for homepage Ask Talance button
        tippy("#atg-button", {
          theme: "light",
          content:
            "<p class='mb-0 text-center px-2' style='font-size: 1.25rem;'>FAQ's that are <strong>FNA (Frequently Never Answered) </strong>- We answer all the tough questions - <strong>Challenge us!</strong></p>",
          maxWidth: 450,
          placement: "bottom"
        });
      });
    })(jQuery);
  }
};

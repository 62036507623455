<template>
  <div id="blog-card" class="row">
    <div class="col-lg-6 text-center py-4 mb-4" v-for="post in posts">
      <div class="inner-container h-100 mx-auto">
        <a :href="post.link" class="block">
          <img :src="post.thumbnail" :alt="post.title" width="100%" />
          <div class="blog-container py-3 px-4">
            <p class="blog-title" v-html="post.title"></p>
            <p v-html="post.excerpt"></p>
          </div>
        </a>
        <div class="row blog-button text-center" v-if="post.video">
          <div class="col-6">
            <div class="inner">
              <button
                class="btn cpl-arrow-btn video-button btn-block"
                @click="post.modal = true"
              >
                Watch Video
              </button>
            </div>
          </div>
          <div class="col-6">
            <div class="inner">
              <a :href="post.link" class="btn cpl-arrow-btn btn-block"
                >Read More
              </a>
            </div>
          </div>
        </div>
        <div class="row blog-button text-center" v-else>
          <div class="col-12">
            <div class="inner">
              <a :href="post.link" class="btn cpl-arrow-btn btn-block"
                >Read More
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-overlay" v-if="post.modal">
        <div class="modal-component">
          <section class="modal-component-header d-flex">
            <button
              type="button"
              class="ml-auto close-modal-btn"
              @click="closeModal(post)"
            >
              <i class="fal fa-times-circle fa-lg"></i>
            </button>
          </section>
          <section class="modal-component-body">
            <div class="inner-body">
              <div class="row">
                <div class="col-12 video-container">
                  <div class="embed-container">
                    <iframe
                      :src="post.video"
                      frameborder="0"
                      webkitAllowFullScreen
                      mozallowfullscreen
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12 mt-4">
          <paginate
            v-model="pageNum"
            :page-count="maxPage"
            :page-range="3"
            :margin-pages="2"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :click-handler="pageClick"
            :container-class="'pagination'"
            :page-class="'page-item'"
          >
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "../Bus.js";

export default {
  props: {
    queriedCat: null
  },
  data() {
    return {
      posts: null,
      maxPage: 0,
      pageNum: 1,
      keywords: ""
    };
  },
  mounted() {
    if (this.queriedCat === "all") {
      axios.get("/wp-json/atg/blog").then(response => {
        this.posts = response.data[0].posts;
        this.maxPage = response.data[0].maxPages;
      });
    } else {
      Bus.$emit("selectMenu", this.queriedCat);

      let params = {
        category: this.queriedCat
      };
      axios.get("/wp-json/atg/blog", { params }).then(response => {
        this.posts = response.data[0].posts;
        this.maxPage = response.data[0].maxPages;
      });
    }

    Bus.$on("updateCategory", category => {
      let params = {
        category: category
      };
      axios.get("/wp-json/atg/blog", { params }).then(response => {
        this.posts = response.data[0].posts;
        this.maxPage = response.data[0].maxPages;
      });
    });

    Bus.$on("changeKeyword", data => {
      this.keywords = data;
      let params = {
        esearch: data
      };
      axios.get("/wp-json/atg/blog", { params }).then(response => {
        this.posts = response.data[0].posts;
        this.maxPage = response.data[0].maxPages;
      });
    });
  },
  methods: {
    closeModal(post) {
      post.modal = false;
    },
    pageClick(num) {
      let params = "";
      if (this.keywords) {
        params = {
          pageNum: num,
          esearch: this.keywords
        };
      } else {
        params = {
          pageNum: num
        };
      }
      axios.get("/wp-json/atg/blog", { params }).then(response => {
        this.posts = response.data[0].posts;
        this.maxPage = response.data[0].maxPages;
      });
    }
  }
};
</script>

<style lang="scss">
@import "../styles/_global-vars.scss";

#blog-card {
  .inner-container {
    background: $white;
    position: relative;
  }

  .blog-title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .profile {
    width: 100%;
    height: auto;
  }

  .blog-button {
    border: 2px solid $white;
    margin: 0;
    /*position: absolute;*/
    bottom: 0;
    /*transform: translateY(25px);*/
    width: 100%;

    .col-6,
    .col-12 {
      padding: 0;
    }

    .col-6:first-of-type {
      border-right: 2px solid $white;
    }

    .inner {
      background: $accent;
    }

    a,
    button {
      padding: 0.5rem 0.75rem;
      color: $body-color;
    }
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(#000, 0.6);
    margin: auto;
    z-index: 2090;
    cursor: s-resize;

    .modal-component.is-scrollable {
      height: calc(100% - 200px);
      max-height: 500px;

      .modal-component-body {
        height: calc(100% - 79px);
        overflow-y: auto;
      }
    }

    .modal-component {
      display: inline-block;
      position: absolute;
      z-index: 2091;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      animation-name: sm-modal;
      animation-duration: 400ms;
      transition-timing-function: ease-in;
      height: initial;
      max-width: 1280px;
      width: calc(100% - 20px);
      background-color: $white;
      border-top-right-radius: 30px;
      border-bottom-left-radius: 30px;
      cursor: auto;

      &-header {
        color: $white;
        background-color: $primary;
        padding: 0.75rem 1.25rem;
        border-top-right-radius: 30px;

        .close-modal-btn {
          border: none;
          color: $white;
          background-color: transparent;
        }
      }

      &-body {
        background-color: #e9eef0;
        padding: 0.75rem 1.25rem;
      }

      &-footer {
        padding: 0.75rem 1.25rem;
        background-color: $white;
        border-bottom-left-radius: 30px;
      }
    }

    @keyframes sm-modal {
      from {
        transform: translate(-50%, -150%);
        opacity: 0;
      }
      to {
        transform: translate(-50%, -50%);
        opacity: 1;
      }
    }
  }

  .video-container {
    height: 735px;
    width: 100%;

    .embed-container {
      position: relative;
      padding-bottom: 59%;
      height: 0;
      overflow: hidden;
      max-width: 100%;

      iframe {
        min-height: 400px;
        width: 100%;
      }

      iframe,
      object,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .pagination {
    li {
      background-color: $primary;
      padding: 0.5rem 0.75rem;
      margin: 0 0.25rem;
      color: white;
      border-radius: 0;
      transition: all 500ms;

      &:hover {
        background-color: $accent;
      }

      a {
        background-color: transparent;
        padding: 0 !important;
        margin: 0 !important;
      }
    }

    li.active {
      a {
        font-weight: bold !important;
      }

      &:hover {
        background-color: $primary;
        cursor: initial;
      }
    }
  }
}
</style>

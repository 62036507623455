import "bootstrap";
import jQuery from "jquery";
import "./style.scss";

import Router from "./util/Router";
import common from "./routes/common";
import home from "./routes/home";
import ourTeam from "./routes/ourTeam";
import contractTeam from "./routes/contractTeam";

import ourStory from "./routes/ourStory";
import talanceContract from "./routes/talanceContract";
import { askTalance } from "./askTalance";

import WebFont from "webfontloader";

import "owl.carousel";

import {
  TweenMax,
  TimelineLite,
  Power2,
  Elastic,
  CSSPlugin
} from "gsap/TweenMax";

import { dom, library } from "@fortawesome/fontawesome-svg-core";
import { faPrint, faPlus, faQuoteLeft } from "@fortawesome/pro-solid-svg-icons";
import {
  faBars,
  faPlayCircle,
  faChevronDown,
  faEnvelope,
  faAngleRight,
  faAngleLeft
} from "@fortawesome/pro-regular-svg-icons";
import {
  faTimes,
  faTimesCircle,
  faSearch,
  faCircle,
  faLongArrowLeft,
  faLongArrowRight,
  faPhoneAlt,
  faMobileAlt
} from "@fortawesome/pro-light-svg-icons";
import {
  faLinkedin,
  faTwitterSquare,
  faFacebookSquare,
  faInstagram,
  faLinkedinIn,
  faFacebookF,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";

// Add all icons to the library so you can use it in your page
library.add(
  faBars,
  faPlayCircle,
  faChevronDown,
  faEnvelope,
  faTimes,
  faTimesCircle,
  faSearch,
  faPhoneAlt,
  faLinkedin,
  faTwitterSquare,
  faFacebookSquare,
  faInstagram,
  faLinkedinIn,
  faCircle,
  faLongArrowLeft,
  faLongArrowRight,
  faAngleRight,
  faAngleLeft,
  faPrint,
  faFacebookF,
  faTwitter,
  faMobileAlt,
  faPlus,
  faQuoteLeft
);

import Promise from "es6-promise";

require("es6-promise").polyfill();

import axios from "axios";

window.axios = axios;

// Vue Stuff
import Vue from "vue/dist/vue.min.js";

window.Event = new Vue();

window.Vue = Vue;

Vue.use(VueRouter);

import Multiselect from "vue-multiselect";

Vue.component("multiselect", Multiselect);

import NProgress from "nprogress";
import VueRouter from "vue-router";

window.NProgress = NProgress;

dom.i2svg();

dom.watch({
  autoReplaceSvgRoot: document,
  observeMutationsRoot: document.body
});

// Initialize webfonts
WebFont.load({
  google: {
    families: ["Droid Sans", "Source Sans Pro", "Montserrat:400,500,700"]
  }
});

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  home,
  ourTeam,
  contractTeam,
  ourStory,
  talanceContract
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());

if (document.querySelector("#atg-blog")) {
  askTalance();
}
